<template>
    <!-- Footer Start -->
    <div
        class="container-fluid bg-dark text-white-50 footer pt-5 mt-5"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-3">
                    <section>
                        <h5 class="text-light">Ecomorɘ</h5>
                        <p class="btn-link">Cửa hàng hạt dinh dưỡng Organic</p>
                    </section>
                </div>
                <div class="col-12 col-md-3">
                    <section>
                        <h5 class="text-light">Về chúng tôi</h5>
                        <p class="d-flex flex-column">
                            <a href="https://www.facebook.com/ecomore.vn" class="btn-link" target="_blank" rel="noopener noreferrer">
                                Giới thiệu về Ecomorɘ
                            </a>
                            <span class="btn-link">Danh sách cửa hàng</span>
                        </p>
                    </section>
                </div>
                <div class="col-12 col-md-3">
                    <section>
                        <h5 class="text-light">Hỗ trợ khách hàng</h5>
                        <p class="d-flex flex-column">
                            <span class="btn-link">
                                Email: shop.ecomore@gmail.com
                            </span>
                            <span class="btn-link">
                                Điện thoại: +84 778 988
                            </span>
                        </p>
                    </section>
                </div>
                <div class="col-12 col-md-3">
                    <section>
                        <h5 class="text-light">Chăm sóc khách hàng</h5>
                        <p class="d-flex flex-column">
                            <span class="btn-link">
                                Email: shop.ecomore@gmail.com
                            </span>
                            <span class="btn-link">
                                Điện thoại: +84 778 988
                            </span>
                        </p>
                    </section>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="copyright">
                <div class="row">
                    <div
                        class="col-md-6 text-center text-md-start mb-3 mb-md-0"
                    >
                        <a href="https://www.facebook.com/ecomore.vn" target="_blank" rel="noopener noreferrer" class="border-bottom">Ecomorɘ</a>.

                        <!--/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. ***/-->
                        Designed By
                        <a class="border-bottom">HTML Codex</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer End -->
</template>